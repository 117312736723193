#reviewer-feedback-modal-header {
  margin-bottom: 1rem;

  font-size: 16pt;
}

#reviewer-feedback-modal-body {
  padding: 1rem;
  border: 1px solid lightgrey;
  border-radius: 5px;

  white-space: pre-wrap;
  word-wrap: break-word;

  p {
    margin-bottom: 0rem;
  }
}

#reviewer-feedback-modal-footer {
  display: flex;
  margin-top: 1rem;
}

